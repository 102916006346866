import React, { useState, useEffect } from "react";
import logonew from "../assests/medialogo.png";
import navArrow from "../assests/navimg/Default.png";
import { Link, NavLink } from "react-router-dom";
import cs_img1 from "../assests/navimg/cs_img1.png";
import cs_img2 from "../assests/navimg/cs_img2.png";
import cs_img3 from "../assests/navimg/cs_img3.png";
import { toast } from "react-toastify";
export default function Nav() {
  //Enquiry Form API
  const [formData, setFormData] = useState({
    name: "",
    last: "",
    email: "",
    phone: "",
    service: "",
    jobtitle: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      formData.last === "" ||
      formData.name === "" ||
      formData.email === "" ||
      formData.phone === "" ||
      formData.jobtitle === "" ||
      formData.service === "" ||
      formData.message === ""
    ) {
      toast.error("Please fill in all fields");
    } else {
      // Add your form submission logic here
      fetch("https://wondar-dev.katsamsoft.com/api/resource/KM%20Enquiry", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Cookie:
            "full_name=Guest; sid=Guest; system_user=no; user_id=Guest; user_image=",
        },
        body: JSON.stringify({
          email: formData.email,
          contact_number: formData.phone,
          custom_job_title: formData.jobtitle,
          subject: formData.service,
          message: formData.message,
          custom_first_name: formData.name,
          custom_last_name: formData.last,
        }),
      })
        .then((response) => {
          if (response.ok) {
            // Clear form fields after successful submission
            setFormData({
              name: "",
              last: "",
              email: "",
              phone: "",
              jobtitle: "",
              service: "",
              message: "",
            });
            // Show success notification
            toast.success("Form submitted successfully!");
          } else {
            // Show error notification if submission fails
            toast.error("Failed to submit form. Please try again later.");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          // Show error notification if submission fails
          toast.error("Failed to submit form. Please try again later.");
        });
    }
  };

  //End
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, visible]);

  return (
    <>
      <div className="bgnew">
        <nav
          id="navbar"
          className={`navbar navbar-expand-lg bg-body-tertiary ${
            visible ? "navbar-show" : "navbar-hide"
          }`}
        >
          <div class="upper-line"></div>

          <div className="container-fluid">
            <Link className="navbar-brand d-md-block d-sm-none" to="/">
              <img src={logonew} className="img-fluid logotop" alt="Logo" />
            </Link>
            <Link className="navbar-brand d-md-none d-sm-block" to="/">
              <img src={logonew} className="img-fluid logotop" alt="Logo" />
            </Link>

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <div className="row extraclass">
                <div className="col-12 p-0 d-none d-md-block d-sm-none">
                  <p className="m-0 absnewtxt">
                    <Link to="mailto:namaste@katsammedia.com">
                      namaste@katsammedia.com{" "}
                    </Link>
                    | <Link to="tel:+9178800 01437">
                    
                   Hr Enquiry : +91 7880001437 </Link> | |<Link to="tel:+018989047502"> Business Enquiry : +91 8989047502 </Link>
                  </p>
                </div>

                <div className="divider"></div>

                <div className="col-12 phn d-md-flex p-0">
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
                    <li className="nav-item  d-flex justify-content-between">
                      <NavLink
                        className="nav-link new"
                        activeClassName="active"
                        to="/full-service-advertising-agency"
                      >
                        About
                      </NavLink>

                      <div className="d-sm-block d-md-none nav-arrow">
                        <img src={navArrow} alt="" className="pe-3" />
                      </div>
                    </li>

           

      
                    <li className="nav-item d-flex justify-content-between d-md-none d-sm-block">
                      <NavLink
                        className="nav-link new"
                        activeClassName="active"
                        to="/?id=company-km"
                      >
                        Agencies
                      </NavLink>
                      <div className="d-sm-block d-md-none nav-arrow">
                        <img src={navArrow} alt="" className="pe-3" />
                      </div>
                    </li>{" "}
                    <li className="nav-item dropdown dropdown1 d-md-block d-sm-none">
  <a
    className="nav-link dropdown-toggle new"
    href="#"
    id="navbarDropdownMenuLink"
    role="button"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    Agencies
  </a>
  <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
    <div className="mega-menu collapse" id="navbartoggleContent">
      <div className="row">
        <div className="col-md-3">
          <div className="mega-div">
            <h4>Our Agencies</h4>
            <br />
            <p>
              Katsam Media is committed to tailored solutions, establishing
              specialized agencies for diverse marketing segments. With
              forward-thinking, we continuously evolve, creating new agencies to
              meet evolving market demands in media and branding.
            </p>
            <div className="btnnew pt-sm-3"></div>
          </div>
        </div>

        <div className="col-md-3 p-2">
          <a href="/?id=kalakaari">
            <div className="image-container">
              <img
                src={cs_img3}
                className="img-fluid zoom-img"
                alt="Image 3"
              />
              <h6 className="mega-link1">Kalakaari Studios</h6>
            </div>
          </a>
        </div>

        <div className="col-md-3 p-2">
          <a href="/?id=vibeee">
            <div className="image-container">
              <img
                src={cs_img2}
                className="img-fluid zoom-img"
                alt="Image 2"
              />
              <h6 className="mega-link1">Vibeee Productions </h6>
            </div>
          </a>
        </div>
        <div className="col-md-3 p-2">
          <a href="/?id=rangeen">
            <div className="image-container">
              <img
                src={cs_img1}
                className="img-fluid zoom-img"
                alt="Image 1"
              />
              <h6 className="mega-link1">Rangeen Networks</h6>
            </div>
          </a>
        </div>
      </div>
    </div>
  </ul>
</li>

                    <li className="nav-item d-sm-block d-md-none d-flex justify-content-between">
                      <NavLink
                        className="nav-link new"
                        activeClassName="active"
                        to="/casestudies"
                      >
                        Katsam Experience{" "}
                      </NavLink>
                      <div className="d-sm-block d-md-none nav-arrow">
                        <img src={navArrow} alt="" className="pe-3" />
                      </div>
                    </li>
                    <li className="nav-item d-sm-block d-md-none d-flex justify-content-between">
                      <NavLink
                        className="nav-link new"
                        activeClassName="active"
                        to="/blog"
                      >
                        Katsam Intellect{" "}
                      </NavLink>
                      <div className="d-sm-block d-md-none nav-arrow">
                        <img src={navArrow} alt="" className="pe-3" />
                      </div>
                    </li>
                    <li className="nav-item d-flex d-sm-none d-md-block justify-content-between">
                      <NavLink
                        className="nav-link new"
                        activeClassName="active"
                        to="/casestudies"
                      >
                        Katsam Experience{" "}
                      </NavLink>
                      <div className="d-sm-block d-md-none nav-arrow">
                        <img src={navArrow} alt="" className="pe-3" />
                      </div>
                    </li>{" "}
                    <li className="nav-item d-flex d-sm-none d-md-block justify-content-between">
                      <NavLink
                        className="nav-link new"
                        activeClassName="active"
                        to="/blog"
                      >
                        Katsam Intellect
                      </NavLink>
                      <div className="d-sm-block d-md-none nav-arrow">
                        <img src={navArrow} alt="" className="pe-3" />
                      </div>
                    </li>{" "}
                    <li className="nav-item d-flex justify-content-between">
                      <NavLink
                        className="nav-link new"
                        activeClassName="active"
                        to="/Career-Growth-Opportunities"
                      >
                        Career & People
                      </NavLink>
                      <div className="d-sm-block d-md-none nav-arrow">
                        <img src={navArrow} alt="" className="pe-3" />
                      </div>
                    </li>{" "}
                    <li className="nav-item d-flex justify-content-between">
                      <NavLink
                        className="nav-link new"
                        activeClassName="active"
                        to="/contact-us"
                      >
                        Contact
                      </NavLink>
                      <div className="d-sm-block d-md-none nav-arrow">
                        <img src={navArrow} alt="" className="pe-3" />
                      </div>
                    </li>
                  </ul>

                  <div className="cta">
                    <div className="btnnew pt-sm-3 pt-md-0">
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-12 lg">
                <button
                  className="ctabtn d-flex"
                  data-toggle="modal"
                  data-target=".bd-example-modal-lg"
                >
                  {" "}
                  Let’s Talk &nbsp;{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="9"
                    viewBox="0 0 22 9"
                    fill="none"
                  >
                    <path
                      d="M0.122192 8.35693L20.6191 7.99916"
                      stroke="white"
                    ></path>
                    <path
                      d="M0.0680279 5.25522L17.5654 4.94981L11.1372 1.16351"
                      stroke="white"
                      stroke-width="2"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </nav>

        <div
          class="modal fade bd-example-modal-lg"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content ctabtnpopup">
              <div class="modal-body p-0">
                <div className="container">
                  <div className="row">
                    <div className="col-12 p-20 black-div align-self-center">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <button
                            type="button closectabtn"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                          <h1>Any Enquiry</h1>
                          <div class="col-6">
                            <input
                              type="text"
                              placeholder="Full Name *"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          <div class="col-6">
                            <input
                              type="text"
                              name="last"
                              placeholder="last Name *"
                              value={formData.last}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          <div class="col-6">
                            <input
                              type="tel"
                              placeholder="Contact Number *"
                              name="phone"
                              value={formData.phone}
                              onChange={handleChange}
                              minLength={10}
                              maxLength={10}
                              required
                            />
                          </div>
                          <div class="col-6">
                            <input
                              type="text"
                              name="email"
                              placeholder="Email ID *"
                              value={formData.email}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          <div class="col-6">
                            <input
                              type="text"
                              name="service"
                              placeholder="Company / Brand *"
                              value={formData.service}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          <div class="col-6">
                            <input
                              type="text"
                              name="jobtitle"
                              placeholder="Job Title *"
                              value={formData.jobtitle}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          <div className="col-12">
                            <textarea
                              placeholder="Tell Us Everything"
                              name="message"
                              className="p-0 pt-2 pb-4"
                              value={formData.message}
                              onChange={handleChange}
                            ></textarea>
                          </div>
                        </div>
                        <div>
                          <button
                            className="ctabtn d-flex"
                            data-toggle="modal"
                            data-target=".bd-example-modal-lg"
                          >
                            Request a Callback
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
