import React, { useState } from "react";
import Cta from "../Include/Cta";
import Footer from "../Include/Footer";
import Nav from "../Navbar.js/Nav";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';


const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    last: "",
    email: "",
    phone: "",
    service: "",
    jobtitle: "",
    message: "",
  });

  const customToastStyle = {
    background: '#CA2529', // Change the background color of the toast
    color: 'white',     // Change the text color of the toast
    fontFamily: "Poppins"
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value});
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform form validation
    if (formData.last === "" || formData.name === "" || formData.email === "" || formData.phone === "" || formData.jobtitle === "" || formData.service === "" || formData.message === "") {
      toast.error("Please fill in all fields");
    } else {
      // Add your form submission logic here
      fetch('https://wondar-dev.katsamsoft.com/api/resource/KM%20Enquiry', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Cookie': 'full_name=Guest; sid=Guest; system_user=no; user_id=Guest; user_image='
        },
        body: JSON.stringify({
          email: formData.email,
          contact_number: formData.phone,
          custom_job_title: formData.jobtitle,
          subject: formData.service,
          message: formData.message,
          custom_first_name: formData.name,
          custom_last_name: formData.last
        })
      })
      .then(response => {
        if (response.ok) {
          // Clear form fields after successful submission
          setFormData({
            name: "",
            last: "",
            email: "",
            phone: "",
            jobtitle: "",
            service: "",
            message: "",
          });
          // Show success notification
          toast.success("Form submitted successfully!");
        } else {
          // Show error notification if submission fails
          toast.error("Failed to submit form. Please try again later.");
        }
      })
      .catch(error => {
        console.error('Error:', error);
        // Show error notification if submission fails
        toast.error("Failed to submit form. Please try again later.");
      });
    }
  };

  return (
    <>


<Helmet>
    <title> Connect for Growth | Build Meaningful Relationships </title>
        <meta name="description" content="Connect for Growth " />
      </Helmet>



     <ToastContainer className={customToastStyle}/>
      <section className="hero-section">
        <div className="banner-hero-about">
          <Nav />
          <div className="container-fluid">
            <div className="row">
            
              <div className="col-sm-8">
               <div className='aboutdivcontent'>
                <h2> Connect for <br></br>Creative Collaborations Today!</h2>
                <p>Explore innovative strategies, tailor-made solutions, and collaborative partnerships to elevate your brand’s presence. Contact us now for transformative results.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      <section className="bannercontact">
        
      <div class="menu d-none d-sm-none d-md-block">
          <div class="menu__item">
            <div class="marquee">
              <div class="marquee__inner">
                <span className="d-block d-sm-none d-md-block">kATSAM MEDIA</span>
                <span className="d-block d-sm-none d-md-block">kATSAM MEDIA</span>
                <span className="d-block d-sm-none d-md-block">kATSAM MEDIA</span>

                <span>kATSAM MEDIA</span>
              </div>
            </div>
          </div>
        </div>
        <div className="headcontainer">
            <div class="red-circle"></div>
            <h5 className="headtitle">
              Connect Us
              <div className="strip-red"></div>
            </h5>
          </div>

        <div className="contact-top">
          <div className="container-fluid">
         
            <div className="row">
             
              <div className="col-sm-12  col-lg-4 col-md-4 p-0">
                <div className="contact-right">
                  <div className="any-request">
                    <ul className="m-0 p-0">
                      <h4>Main Office  </h4>
                      <li>
                        <h6>Indore </h6>
                        202, EA-135, Ring Rd, opposite bharat petrol pump, EB Sector, Vijay Nagar, Indore, Madhya Pradesh 452016
                      </li>
                      <li>
                        <h6>Email</h6><Link to="mailto:namaste@katsammedia.com"><span className="text-white">namaste@katsammedia.com</span></Link>
                      </li>
                      <li>
                        <h6>Hr Enquiry</h6><Link to="tel:+9178800 01437"><span className="text-white">+91 78800 01437</span></Link> 
                      </li>
                      <li>
                        <h6>Business Enquiry</h6><Link to="tel:+9189890 47502"><span className="text-white">+91 89890 47502</span></Link> 
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-sm-12  col-lg-4 col-md-4 p-0">
                <div className="contact-right">
                  <div className="any-request">
                    <ul className="m-0 p-0">
                      <h4>Branch Office </h4>
                      <li>
                        <h6>Mumbai </h6>
                        WeWork Vaswani Chambers, 1st floor, 264-265, Dr. Annie Besant Rd, Worli, Maharashtra, 400051

                      </li>
                      <li>
                        <h6>Email</h6><Link to="mailto:namaste@katsammedia.com"><span className="text-white">namaste@katsammedia.com</span></Link>
                      </li>
                      <li>
                        <h6>Hr Enquiry</h6><Link to="tel:+9178800 01437"><span className="text-white">+91 78800 01437</span></Link> 
                      </li>
                      <li>
                        <h6>Business Enquiry</h6><Link to="tel:+9189890 47502"><span className="text-white">+91 89890 47502</span></Link> 
                      </li>
                    </ul>
                  </div>
                </div>
              </div>


              <div className="col-sm-12  col-lg-4 col-md-4 p-0">
                <div className="contact-right">
                  <div className="any-request">
                    <ul className="m-0 p-0">
                      <h4>Vibeee Studio</h4>
                      <li>
                        <h6>Indore </h6>
                        203, EA-135, Ring Rd, opposite bharat petrol pump, EB Sector, Vijay Nagar, Indore, Madhya Pradesh 452016
                      </li>
                      <li>
                        <h6>Email</h6><Link to="mailto:namaste@katsammedia.com"><span className="text-white">namaste@katsammedia.com</span></Link>
                      </li>
                      <li>
                        <h6>Hr Enquiry</h6><Link to="tel:+9178800 01437"><span className="text-white">+91 78800 01437</span></Link> 
                      </li>
                      <li>
                        <h6>Business Enquiry</h6><Link to="tel:+9189890 47502"><span className="text-white">+91 89890 47502</span></Link> 
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </section>


      <div class="gradient-container">
        <div class="conic-gradient"></div>
      </div>
      <section className="contact-form">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="contacttitle">
                <h4>Fill up, we’ll give you a call</h4>
                <span className="contact-strip2"></span>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6 col-12">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="First Name *"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="col-md-6 col-12">
                    <input
                      type="text"
                      className="form-control"
                      name="last"
                      placeholder="Last Name *"
                      value={formData.last}
                      onChange={handleChange}
                      required
                    />
                  </div>
                 
                  <div className="col-md-6 col-12">
                    <input
                      type="tel"
                      className="form-control"
                      name="phone"
                      placeholder="Contact Number *"
                      value={formData.phone}
                      onChange={handleChange}
                    minLength={10}
                    maxLength={10}
                    required
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Email ID *"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                 

               

                  <div className="col-md-6 col-12">
                    <input
                      type="text"
                      className="form-control"
                      name="service"
                      placeholder="Company / Brand *"
                      value={formData.service}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <input
                      type="text"
                      className="form-control"
                      name="jobtitle"
                      placeholder="Job Title *"
                      value={formData.jobtitle}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="col-12">
                    <textarea
                      className="form-control"
                      rows="4"
                      name="message"
                      placeholder="Tell Us Everything"
                      value={formData.message}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="submitbtn">
                    <button type="submit" className="ctabtn-contact">Request a Callback</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Cta />
      <Footer />
    </>
  );
};

export default Contact;
